import { useMutation } from "@apollo/client";
import { GET_ALL_PROJECTS, UPDATE_PROJECT } from "../utils/getProjects";
import { useEffect, useState } from "react";
import React from "react";

function EditProject(data: any) {
  const [edit, setEdit] = useState(false);
  const [project, setProject] = useState();
  const [editProject, { loading, error }] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      { query: GET_ALL_PROJECTS }, // DocumentNode object parsed with gql
      "GetAllProjects", // Query name
    ],
  });

  useEffect(() => {
    setProject(data.data);
  }, []);

  function updateProject(id: any) {
    // @ts-ignore
    editProject({ variables: { ...project } })
      .then((res) => {
        setEdit(false);
      })
      .catch((error) => console.log(error));
  }

  function handleInputChange(event: any) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "tech") {
      value = value.split(",");
    }

    setProject({
      // @ts-ignore
      ...project,
      [name]: value,
    });
  }

  if (loading) return <>"Submitting..."</>;
  if (error) return <>`Submission error! ${error.message}`</>;
  if (edit === true) {
    return (
      <>
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "auto",
            zIndex: 1000,
          }}
          className={
            "bg-slate2 opacity-2 p-8 rounded shadow border-2 border-mint2"
          }
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // @ts-ignore
              updateProject(project.id);
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
            className={"text-[4vh] w-[80%] mx-auto"}
          >
            <label className={"text-mint2 mb-4"}>
              Client:
              <input
                type="text"
                name="client"
                onChange={(event) => handleInputChange(event)}
                // @ts-ignore
                defaultValue={project.client}
                className={"bg-mint2 rounded text-slate2 w-[100%] px-2"}
              />
            </label>
            <label className={"text-mint2 mb-4"}>
              Name:
              <input
                type="text"
                name="name"
                onChange={(event) => handleInputChange(event)}
                // @ts-ignore
                defaultValue={project.name}
                className={"bg-mint2 rounded text-slate2 w-[100%] px-2"}
              />
            </label>
            <label className={"text-mint2 mb-4"}>
              Tech:
              <input
                type="text"
                name="tech"
                onChange={(event) => handleInputChange(event)}
                // @ts-ignore
                defaultValue={project.tech}
                className={"bg-mint2 rounded text-slate2 w-[100%] px-2"}
              />
            </label>
            <label className={"text-mint2 mb-4"}>
              Description:
              <textarea
                name="description"
                rows={6}
                onChange={(event) => handleInputChange(event)}
                // @ts-ignore
                defaultValue={project.description}
                className={"bg-mint2 rounded text-slate2 w-[100%] px-2"}
              />
            </label>
            <button
              type="submit"
              className="w-full max-w-[300px] mx-auto text-[3vh] rounded bg-transparent text-[2rem] text-mint2 border-2 border-mint2 font-bold px-6 py-2.5 font-medium uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Update
            </button>
          </form>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            className="w-6 h-6 stroke-mint2"
            onClick={() => setEdit(false)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </>
    );
  } else {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          className="w-6 h-6 stroke-mint2"
          onClick={() => setEdit(true)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
          />
        </svg>
      </>
    );
  }
}
export default EditProject;
