import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("loggedIn"));
  const isAdmin = sessionStorage.getItem("isAdmin");
  const pages = ["Home", "About", "Contact"];
  const [menuOpen, setMenuOpen] = useState(false);
  let settings;
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const goToPage = (page: string) => {
    if (page === "Logout") {
      sessionStorage.setItem("loggedIn", "false");
      sessionStorage.setItem("isAdmin", "false");
      window.dispatchEvent(new Event("storage"));
    }
    navigate(`/${page}`);
  };

  window.addEventListener("storage", () => {
    setLoggedIn(sessionStorage.getItem("loggedIn"));
  });

  const isAdminSettings = ["Profile", "Account", "Dashboard", "Project"];

  if (loggedIn === "true" && user !== undefined) {
    settings = ["Chat", "Logout"];

    if (isAdmin === "true") {
      settings = [...isAdminSettings, ...settings];
    }
  } else {
    settings = ["Login"];
  }

  return (
    <nav
      className="flex-no-wrap relative flex w-full items-center justify-between bg-neutral-100 py-4 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap "
      data-te-navbar-ref
    >
      <div className="flex w-full flex-wrap items-center justify-between px-6 max-w-md">
        <button
          className="block flex border-0 bg-transparent py-2 px-2.5 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
          type="button"
          data-te-collapse-init
          data-te-target="#navbarSupportedContent1"
          aria-controls="navbarSupportedContent1"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => (menuOpen ? setMenuOpen(false) : setMenuOpen(true))}
        >
          <span className="[&>svg]:w-8">
            {menuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8 !ml-[-0.925rem]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-8 w-8"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </span>
        </button>
        <div
          className="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto lg:h-11"
          id="navbarSupportedContent1"
          data-te-collapse-item
        >
          <a
            className="xs:invisible lg:visible flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mt-0 "
            href="/"
          >
            <img
              src="https://tecdn.b-cdn.net/img/logo/mdb-transaprent-noshadows.png"
              style={{ height: "15px" }}
              alt=""
              loading="lazy"
              className={"hidden"}
            />
          </a>
          <ul
            className="list-style-none mr-auto flex flex-col pl-0 lg:flex-row"
            data-te-navbar-nav-ref
          >
            {pages.map((page) => (
              <li
                key={page}
                className="lg:pr-2 text-xl cursor-pointer"
                data-te-nav-item-ref
                onClick={() => goToPage(page === "Home" ? "" : page)}
              >
                <span
                  className="w-[100%] text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-zinc-400"
                  data-te-nav-link-ref
                >
                  {page}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="h-11 flex items-center justify-end w-full flex-wrap max-w-xs mr-2">
        <div className="h-11 flex items-center justify-end w-full flex-wrap max-w-xs mr-2">
          <span
            className={`invisible mr-4 text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 [&.active]:text-black/90 dark:[&.active]:text-neutral-400`}
          >
            <span className="[&>svg]:w-7 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-7 w-7"
              >
                <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
              </svg>
            </span>
          </span>
          <div className="relative" data-te-dropdown-ref>
            <span
              className="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
              id="dropdownMenuButton2"
              role="button"
              data-te-dropdown-toggle-ref
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path
                  fillRule="evenodd"
                  d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <ul
              className="absolute left-auto right-0 z-[1000] float-left m-0 mt-1 hidden min-w-max list-none overflow-hidden rounded-sm border-none bg-white bg-clip-padding text-left w-128 shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block p-5"
              aria-labelledby="dropdownMenuButton2"
              data-te-dropdown-menu-ref
              style={{ backgroundColor: "#F5F5F7" }}
            >
              {settings.map((setting) =>
                setting === "Logout" && user !== undefined ? (
                  <li key={setting} className="lg:pr-2 text-xl">
                    <Authenticator>
                      {({ signOut, user }) => (
                        <span
                          className="w-[100%] cursor-pointer text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-zinc-400 leading-9"
                          onClick={signOut}
                          data-te-nav-link-ref
                        >
                          Sign out
                        </span>
                      )}
                    </Authenticator>
                  </li>
                ) : (
                  <li
                    key={setting}
                    className="lg:pr-2 text-xl"
                    data-te-nav-item-ref
                    onClick={() => goToPage(setting)}
                  >
                    <span
                      className="w-[100%] cursor-pointer text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-zinc-400 leading-9"
                      data-te-nav-link-ref
                    >
                      {setting}
                    </span>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <div className={"max-w-[2rem] mr-4"}>
          <a href={"https://www.linkedin.com/in/matthew-robison/"}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100%"
              viewBox="0 0 635 540"
              enableBackground="new 0 0 635 540"
            >
              <path
                fill="slate2"
                opacity="1.000000"
                stroke="none"
                d="
M1.000001,507.000000
	C1.000000,348.642578 1.000000,190.285187 1.348588,31.352690
	C4.564389,25.584520 6.927793,20.020687 10.414048,15.283136
	C15.978903,7.720929 24.168917,3.711784 33.000000,1.000000
	C190.357407,1.000000 347.714813,1.000000 505.688812,1.355003
	C523.205139,4.767903 538.002197,20.733511 538.001770,41.211578
	C537.998413,189.194855 538.012512,337.178131 537.957764,485.161377
	C537.955383,491.587067 537.544067,498.031616 536.961487,504.434418
	C535.458313,520.953064 517.744446,536.992798 501.135498,536.993896
	C346.819611,537.004211 192.503677,537.046509 38.187893,536.918701
	C24.657312,536.907532 14.719544,529.579407 7.367126,518.683228
	C4.897429,515.023132 3.103041,510.907440 1.000001,507.000000
M211.000000,298.500000
	C211.000000,351.243866 211.000000,403.987762 211.000000,456.618713
	C237.389938,456.618713 263.107971,456.618713 289.000000,456.618713
	C289.000000,454.413818 289.000000,452.588379 289.000000,450.762939
	C289.000000,409.774048 288.822021,368.783813 289.137543,327.797363
	C289.207733,318.676605 290.368042,309.438843 292.172058,300.485809
	C294.986298,286.519287 301.474487,274.819916 315.537842,268.844177
	C324.577118,265.003204 334.074249,264.207947 343.564392,265.137299
	C356.147491,266.369476 366.181671,272.177826 372.014618,284.013855
	C376.881439,293.889496 378.806305,304.548615 378.875885,315.299072
	C379.169189,360.618225 379.000000,405.940369 379.000000,451.261414
	C379.000000,453.040497 379.000000,454.819580 379.000000,456.688171
	C405.249634,456.688171 430.966797,456.688171 456.852753,456.688171
	C456.911743,455.577332 456.984497,454.768250 456.993378,453.958466
	C457.332520,423.034271 458.021912,392.108459 457.887207,361.186249
	C457.779663,336.492828 456.674957,311.803650 455.998688,287.112946
	C455.647064,274.274841 452.841431,261.842224 448.844269,249.756958
	C441.218353,226.700226 426.356140,209.993652 403.047546,202.276459
	C393.878387,199.240677 383.917267,198.536072 374.282990,196.979172
	C357.148224,194.210175 340.698700,196.693329 325.001282,204.017532
	C310.581665,210.745544 298.706879,220.446777 290.274994,234.131271
	C289.494812,235.397461 288.103027,236.286789 285.909729,238.394058
	C285.909729,225.602203 285.909729,214.506760 285.909729,203.305954
	C260.762634,203.305954 236.045319,203.305954 211.000000,203.305954
	C211.000000,234.907166 211.000000,266.203583 211.000000,298.500000
M160.000000,358.500000
	C160.000000,306.751709 160.000000,255.003387 160.000000,203.390869
	C133.594727,203.390869 107.874557,203.390869 82.253281,203.390869
	C82.253281,288.043274 82.253281,372.304565 82.253281,456.697510
	C108.226631,456.697510 133.945740,456.697510 160.000000,456.697510
	C160.000000,424.097473 160.000000,391.798737 160.000000,358.500000
M158.533234,96.005035
	C158.246353,95.421082 158.068634,94.742569 157.656631,94.267006
	C147.654816,82.721817 135.893463,75.383247 119.741920,75.823837
	C107.109276,76.168427 96.488388,80.894791 88.329269,89.581093
	C79.126968,99.377998 74.298935,111.399048 75.848320,125.607254
	C77.001335,136.180664 81.036758,145.269318 88.263046,152.516434
	C98.296486,162.578781 110.480629,167.687897 125.244064,166.127625
	C136.504684,164.937546 146.270462,160.622925 153.671173,152.563828
	C167.958847,137.005020 170.926636,115.154762 158.533234,96.005035
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M1.000001,507.468658
	C3.103041,510.907440 4.897429,515.023132 7.367126,518.683228
	C14.719544,529.579407 24.657312,536.907532 38.187893,536.918701
	C192.503677,537.046509 346.819611,537.004211 501.135498,536.993896
	C517.744446,536.992798 535.458313,520.953064 536.961487,504.434418
	C537.544067,498.031616 537.955383,491.587067 537.957764,485.161377
	C538.012512,337.178131 537.998413,189.194855 538.001770,41.211578
	C538.002197,20.733511 523.205139,4.767903 506.152710,1.355003
	C549.303040,1.000000 592.606079,1.000000 636.000000,1.000000
	C636.000000,166.020889 636.000000,331.041779 635.652588,496.393372
	C634.876343,495.669159 634.338806,494.644592 634.034912,493.554871
	C628.415527,473.403992 610.225708,464.203217 591.511780,467.360840
	C571.411316,470.752350 557.901978,489.912506 562.052795,510.842377
	C563.767456,519.488281 567.137024,527.526123 575.003174,532.380188
	C580.390381,535.704590 586.315430,538.157471 592.000000,541.000000
	C395.000000,541.000000 197.999985,541.000000 1.000000,541.000000
	C1.000000,529.921936 1.000000,518.929626 1.000001,507.468658
z"
              />
              <path
                fill="slate2"
                opacity="1.000000"
                stroke="none"
                d="
M592.463196,541.000000
	C586.315430,538.157471 580.390381,535.704590 575.003174,532.380188
	C567.137024,527.526123 563.767456,519.488281 562.052795,510.842377
	C557.901978,489.912506 571.411316,470.752350 591.511780,467.360840
	C610.225708,464.203217 628.415527,473.403992 634.034912,493.554871
	C634.338806,494.644592 634.876343,495.669159 635.652588,496.862000
	C636.000000,501.689240 636.000000,506.378510 635.649170,511.647217
	C634.872559,512.900024 634.257019,513.520020 634.050354,514.254944
	C631.980042,521.617920 628.364624,528.163940 621.944824,532.350769
	C616.662842,535.795471 610.675049,538.157959 605.000000,541.000000
	C600.975464,541.000000 596.950928,541.000000 592.463196,541.000000
M575.487793,525.990173
	C583.894043,533.099731 593.027527,537.614746 604.536682,535.036804
	C618.387512,531.934204 627.795349,522.463806 629.801758,509.160156
	C633.518738,484.514984 611.311707,467.664429 590.581909,472.955811
	C577.437134,476.311035 567.277283,489.021362 566.901978,502.507568
	C566.655457,511.365387 569.559021,518.775330 575.487793,525.990173
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M32.531342,1.000000
	C24.168917,3.711784 15.978903,7.720929 10.414048,15.283136
	C6.927793,20.020687 4.564389,25.584520 1.348588,30.888805
	C1.000000,21.071110 1.000000,11.142219 1.000000,1.000000
	C11.353252,1.000000 21.707968,1.000000 32.531342,1.000000
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M605.468628,541.000000
	C610.675049,538.157959 616.662842,535.795471 621.944824,532.350769
	C628.364624,528.163940 631.980042,521.617920 634.050354,514.254944
	C634.257019,513.520020 634.872559,512.900024 635.649170,512.113342
	C636.000000,521.595642 636.000000,531.191223 636.000000,541.000000
	C625.980103,541.000000 615.958740,541.000000 605.468628,541.000000
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M211.000000,298.000000
	C211.000000,266.203583 211.000000,234.907166 211.000000,203.305954
	C236.045319,203.305954 260.762634,203.305954 285.909729,203.305954
	C285.909729,214.506760 285.909729,225.602203 285.909729,238.394058
	C288.103027,236.286789 289.494812,235.397461 290.274994,234.131271
	C298.706879,220.446777 310.581665,210.745544 325.001282,204.017532
	C340.698700,196.693329 357.148224,194.210175 374.282990,196.979172
	C383.917267,198.536072 393.878387,199.240677 403.047546,202.276459
	C426.356140,209.993652 441.218353,226.700226 448.844269,249.756958
	C452.841431,261.842224 455.647064,274.274841 455.998688,287.112946
	C456.674957,311.803650 457.779663,336.492828 457.887207,361.186249
	C458.021912,392.108459 457.332520,423.034271 456.993378,453.958466
	C456.984497,454.768250 456.911743,455.577332 456.852753,456.688171
	C430.966797,456.688171 405.249634,456.688171 379.000000,456.688171
	C379.000000,454.819580 379.000000,453.040497 379.000000,451.261414
	C379.000000,405.940369 379.169189,360.618225 378.875885,315.299072
	C378.806305,304.548615 376.881439,293.889496 372.014618,284.013855
	C366.181671,272.177826 356.147491,266.369476 343.564392,265.137299
	C334.074249,264.207947 324.577118,265.003204 315.537842,268.844177
	C301.474487,274.819916 294.986298,286.519287 292.172058,300.485809
	C290.368042,309.438843 289.207733,318.676605 289.137543,327.797363
	C288.822021,368.783813 289.000000,409.774048 289.000000,450.762939
	C289.000000,452.588379 289.000000,454.413818 289.000000,456.618713
	C263.107971,456.618713 237.389938,456.618713 211.000000,456.618713
	C211.000000,403.987762 211.000000,351.243866 211.000000,298.000000
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M160.000000,359.000000
	C160.000000,391.798737 160.000000,424.097473 160.000000,456.697510
	C133.945740,456.697510 108.226631,456.697510 82.253281,456.697510
	C82.253281,372.304565 82.253281,288.043274 82.253281,203.390869
	C107.874557,203.390869 133.594727,203.390869 160.000000,203.390869
	C160.000000,255.003387 160.000000,306.751709 160.000000,359.000000
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M158.784271,96.278015
	C170.926636,115.154762 167.958847,137.005020 153.671173,152.563828
	C146.270462,160.622925 136.504684,164.937546 125.244064,166.127625
	C110.480629,167.687897 98.296486,162.578781 88.263046,152.516434
	C81.036758,145.269318 77.001335,136.180664 75.848320,125.607254
	C74.298935,111.399048 79.126968,99.377998 88.329269,89.581093
	C96.488388,80.894791 107.109276,76.168427 119.741920,75.823837
	C135.893463,75.383247 147.654816,82.721817 157.656631,94.267006
	C158.068634,94.742569 158.246353,95.421082 158.784271,96.278015
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M575.234497,525.728882
	C569.559021,518.775330 566.655457,511.365387 566.901978,502.507568
	C567.277283,489.021362 577.437134,476.311035 590.581909,472.955811
	C611.311707,467.664429 633.518738,484.514984 629.801758,509.160156
	C627.795349,522.463806 618.387512,531.934204 604.536682,535.036804
	C593.027527,537.614746 583.894043,533.099731 575.234497,525.728882
M591.096985,507.930298
	C594.636475,505.861084 597.448975,506.575836 599.551514,510.111328
	C601.458313,513.317688 603.583496,516.411621 605.260254,519.732971
	C607.414917,524.001099 610.607666,524.797302 615.429871,523.421753
	C611.594421,517.520996 607.990051,511.975616 604.357056,506.386292
	C610.281921,505.616730 613.762390,502.170349 614.030457,497.353668
	C614.589600,487.302979 612.037292,485.612000 605.531616,484.310852
	C599.034912,483.011505 592.075439,484.025757 585.305542,484.025757
	C585.305542,497.660339 585.305542,510.711731 585.305542,524.030884
	C587.349243,523.930847 589.071838,523.846497 591.015015,523.751343
	C591.015015,518.468750 591.015015,513.660461 591.096985,507.930298
z"
              />
              <path
                fill="slate2"
                opacity="1.000000"
                stroke="none"
                d="
M591.056030,508.391235
	C591.015015,513.660461 591.015015,518.468750 591.015015,523.751343
	C589.071838,523.846497 587.349243,523.930847 585.305542,524.030884
	C585.305542,510.711731 585.305542,497.660339 585.305542,484.025757
	C592.075439,484.025757 599.034912,483.011505 605.531616,484.310852
	C612.037292,485.612000 614.589600,487.302979 614.030457,497.353668
	C613.762390,502.170349 610.281921,505.616730 604.357056,506.386292
	C607.990051,511.975616 611.594421,517.520996 615.429871,523.421753
	C610.607666,524.797302 607.414917,524.001099 605.260254,519.732971
	C603.583496,516.411621 601.458313,513.317688 599.551514,510.111328
	C597.448975,506.575836 594.636475,505.861084 591.056030,508.391235
M604.785217,490.315704
	C600.340515,489.735748 595.895813,489.155762 591.328125,488.559753
	C591.328125,493.307526 591.328125,497.020691 591.328125,500.999603
	C594.619019,500.999603 597.691650,501.476044 600.522827,500.853729
	C602.926514,500.325378 605.782471,499.011383 607.032837,497.117218
	C607.816040,495.930725 605.998352,493.027313 604.785217,490.315704
z"
              />
              <path
                fill="#FFFFFF"
                opacity="1.000000"
                stroke="none"
                d="
M605.066406,490.604980
	C605.998352,493.027313 607.816040,495.930725 607.032837,497.117218
	C605.782471,499.011383 602.926514,500.325378 600.522827,500.853729
	C597.691650,501.476044 594.619019,500.999603 591.328125,500.999603
	C591.328125,497.020691 591.328125,493.307526 591.328125,488.559753
	C595.895813,489.155762 600.340515,489.735748 605.066406,490.604980
z"
              />
            </svg>
          </a>
        </div>
      </div>
    </nav>
  );
}
export default ResponsiveAppBar;
