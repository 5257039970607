import { useQuery } from "@apollo/client";
import React from "react";
import { GET_ALL_PRODUCTS } from "../utils/getProducts";

function ProductList() {
  const { loading, error, data } = useQuery(GET_ALL_PRODUCTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <>
      {
        // @ts-ignore
        data.allProducts.map(({ name, description, price }) => (
          <ul key={name}>
            <li>name: {name}</li>
            <li>desc: {description}</li>
            <li>price: {price}</li>
          </ul>
        ))
      }
    </>
  );
}

export default ProductList;
