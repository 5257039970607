import { gql } from "@apollo/client";

export const CREATE_NEW_CHAT = gql`
  mutation CreateChat(
    $title: String
    $organization: String
    $username: String
    $messages: [String]
  ) {
    createChat(
      title: $title
      organization: $organization
      username: $username
      messages: $messages
    ) {
      id
      title
      organization
      username
      messages
    }
  }
`;
