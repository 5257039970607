import { useMutation } from "@apollo/client";
import { CREATE_NEW_PRODUCT } from "../utils/createNewProduct";
import { GET_ALL_PRODUCTS } from "../utils/getProducts";
import { useState } from "react";
import React from "react";

function AddProduct() {
  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: 0,
    rating: 0,
  });
  const [createProduct, { loading, error }] = useMutation(CREATE_NEW_PRODUCT, {
    refetchQueries: [
      { query: GET_ALL_PRODUCTS }, // DocumentNode object parsed with gql
      "GetAllProducts", // Query name
    ],
  });

  function addNewProduct() {
    createProduct({
      variables: {
        name: product.name,
        description: product.description,
        price: product.price,
        rating: product.rating,
      },
    });
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setProduct({
      ...product,
      [name]: value,
    });
  }

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addNewProduct();
        }}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label>
          Name:
          <input
            type="text"
            name="name"
            onChange={(event) => handleInputChange(event)}
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            name="description"
            onChange={(event) => handleInputChange(event)}
          />
        </label>
        <button type="submit" style={{ maxWidth: "200px" }}>
          Add Product
        </button>
      </form>
    </div>
  );
}
export default AddProduct;
