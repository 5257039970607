import { useMutation } from "@apollo/client";
import { GET_CHATS_BY_USER } from "../utils/getChats";
import { useContext, useEffect, useReducer, useState } from "react";
import React from "react";
import { CREATE_NEW_CHAT } from "../utils/createNewChat";
import {
  ChatActionTypes,
  chatReducer,
  defaultChatState,
} from "../reducers/chatReducer";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ChatContext } from "../context/chatContext";

function NewChatButton() {
  const [edit, setEdit] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const [chat, dispatch] = useReducer(chatReducer, defaultChatState);
  const [newChat, setNewChat] = useState({ ...chat });
  const [createChat, { loading, error }] = useMutation(CREATE_NEW_CHAT, {
    refetchQueries: [
      { query: GET_CHATS_BY_USER }, // DocumentNode object parsed with gql
      "GetChatsByUser", // Query name
    ],
  });
  const { updateChatHandler } = useContext(ChatContext);

  useEffect(() => {
    if (user !== undefined) {
      setNewChat({
        ...chat,
        username: user.username,
      });
    }
  }, [user]);

  function createNewChat() {
    // @ts-ignore
    createChat({ variables: { ...newChat } })
      .then(async (res) => {
        setEdit(false);
        const response = res.data["createChat"];
        const newMessages = response.messages;
        const parsedMessages = newMessages.map((mes: any) => {
          return JSON.parse(mes);
        });

        updateChatHandler({
          type: ChatActionTypes.UPDATE_ACTIVE_CHAT,
          payload: {
            id: response.id,
            title: response.title,
            organization: response.organization,
            username: response.username,
            messages: parsedMessages,
          },
        });
      })
      .catch((error) => console.log(error));
  }

  function handleInputChange(event: any) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    setNewChat({
      ...newChat,
      [name]: value,
    });
  }

  if (edit) {
    return (
      <>
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "auto",
            zIndex: 1000,
            maxWidth: "700px",
          }}
          className={
            "bg-slate2 opacity-2 p-8 rounded shadow-2xl border-2 border-mint2"
          }
        >
          {loading ? (
            <div className="h-[150px] flex justify-center m-auto">
              <div
                className="self-center m-auto border-2 border-mint2z-[1000] h-8 w-8 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-mint2 align-[-0.125em] text-mint2 opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          ) : error ? (
            <>`Submission error! ${error?.message}`</>
          ) : (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  // @ts-ignore
                  createNewChat();
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                }}
                className={"text-[3vh] w-[70%] mx-auto"}
              >
                <label className={"text-mint2 mb-4"}>
                  New Chat Title:
                  <input
                    type="text"
                    name="title"
                    onChange={(event) => handleInputChange(event)}
                    // @ts-ignore
                    defaultValue={newChat.title}
                    className={
                      "bg-[#253b4b] rounded text-mint2 text-2xl w-[100%] my-4 p-2 border-solid border-[1px] border-mint2"
                    }
                  />
                </label>
                <button
                  type="submit"
                  className="w-full max-w-[250px] mx-auto text-[2vh] rounded bg-transparent text-[2rem] text-mint2 border-2 border-mint2 font-bold px-6 py-2.5 font-medium uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Submit
                </button>
              </form>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                className="w-8 h-8 stroke-mint2 mr-[-20px] mt-[-20px]"
                onClick={() => setEdit(false)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <button
          className="p-2 border-[1px] border-mint2 rounded-[2px] my-4 ml-[5%] text-xl flex items-center"
          onClick={() => {
            setEdit(true);
          }}
        >
          New Chat
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </button>
      </>
    );
  }
}
export default NewChatButton;
