import { useEffect, useState } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";

function Login() {
  const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("loggedIn"));
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  if (user !== undefined) {
    if (user.username === "matthew717") {
      sessionStorage.setItem("isAdmin", "true");
    }
    sessionStorage.setItem("loggedIn", "true");
    window.dispatchEvent(new Event("storage"));
  }

  if (user === undefined) {
    sessionStorage.setItem("isAdmin", "false");
    sessionStorage.setItem("loggedIn", "false");
    window.dispatchEvent(new Event("storage"));
  }

  /*useEffect(() => {
    if (loggedIn === "false") {
      getVideo();
    }
  }, [videoRef, loggedIn]);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };
  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");

    const width = 320;
    const height = 240;
    photo.width = width;
    photo.height = height;

    return setInterval(() => {
      ctx.drawImage(video, 0, 0, width, height);
    }, 20);
  };

  const takePhoto = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    const data = photo.toDataURL("image/jpeg").split(";base64,")[1];

    fetch(process.env.REACT_APP_FR_API, {
      method: "POST",
      body: JSON.stringify({
        image: data,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const messages = data.message;
        const checkIsAdmin = () => {
          const adminArray = messages.filter((message) =>
            message.persons.some((person) =>
              person.collections.some(
                (collection) => collection.name === "Admin"
              )
            )
          );
          if (adminArray.length > 0) {
            setLoading(false);
            sessionStorage.setItem("isAdmin", "true");
            sessionStorage.setItem("loggedIn", "true");
            window.dispatchEvent(new Event("storage"));
            setLoggedIn("true");
          } else {
            setLoading(false);
            sessionStorage.setItem("isAdmin", "false");
            sessionStorage.setItem("loggedIn", "false");
            window.dispatchEvent(new Event("storage"));
            setLoggedIn("false");
            setNoUser(true);
          }
        };

        checkIsAdmin();

        const mediaStream = video.srcObject;
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => track.stop());
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }; */

  useEffect(() => {
    if (user === undefined) {
      logOut();
    }
  }, [user]);

  const logOut = () => {
    sessionStorage.setItem("loggedIn", "false");
    sessionStorage.setItem("isAdmin", "false");
    window.dispatchEvent(new Event("storage"));
    setLoggedIn("false");
  };

  /*if (loading) {
    return (
      <div className="flex items-center justify-center h-[80vh]">
        <div
          className="text-blue inline-block h-2 w-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="text-blue text-4xl !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Logging In...
          </span>
        </div>
      </div>
    );
  }

  if (noUser) {
    return (
      <div className="flex flex-col items-center justify-center h-[80vh]">
        <div className=" border-8 border-[#058C42] rounded-full p-10 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#058C42"
            className="w-36 h-36"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
            />
          </svg>
        </div>
        <p className="text-4xl text-blue text-center mt-6">
          We weren't able to find you.
        </p>
        <button
          className=" mt-4 inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xl font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
          onClick={() => navigate(0)}
        >
          Retry?
        </button>
      </div>
    );
  } */

  return (
    /*<div style={{ display: "flex", flexDirection: "column" }}>
      <video
        onCanPlay={() => paintToCanvas()}
        ref={videoRef}
        style={{ display: "none" }}
      />
      <canvas
        ref={photoRef}
        className="max-w-[700px] w-[90%] mx-auto mt-4 rounded"
      />
      <div className="flex justify-center space-x-2">
        <button
          onClick={() => {
            takePhoto();
            setLoading(true);
          }}
          type="button"
          data-te-ripple-init
          data-te-ripple-color="light"
          className="mt-6 inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xl font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
        >
          Login
        </button>
      </div>
    </div>*/
    <Authenticator className="h-[85vh]">
      {({ signOut, user }) => (
        <div
          style={{ display: "flex", justifyContent: "center", height: "85vh" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="flex flex-col items-center justify-center h-[80vh]">
              <div className=" border-8 border-[#058C42] rounded-full p-10 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#058C42"
                  className="w-36 h-36"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                  />
                </svg>
              </div>
              <div className="max-w-[700px] flex justify-center text-5xl text-center p-6">
                You are now logged In {user?.username}
              </div>
              <div className="flex justify-center space-x-2">
                <button
                  onClick={signOut}
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xl font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Authenticator>
  );
}

export default Login;
