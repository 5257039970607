import React from "react";

function About() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
        className={"bg-green2"}
      >
        <div className="flex flex-wrap my-12 mx-auto w-[85%] lg:max-w-[1400px] justify-between items-start mb-20">
          <div className="w-100 text-slate2 lg:mt-[4vh]">
            <img
              src={"/images/matthew-profile.png"}
              alt={"Matthew Robison"}
              className="h-auto mx-auto md:max-w-lg lg:mx-0 lg:w-[40%] xl:max-w-2xl rounded lg:float-left mb-12 lg:mr-12"
            />
            <h4 className="mb-4 text-6xl leading-tight">Hello,</h4>
            <p className="mb-4 leading-tight text-[5vw] md:text-[2.5vh] text-slate2">
              I'm a Full Stack Engineer that's enthusiastic about bringing ideas
              to life. Whether it's a solo project, or one that requires a team,
              my belief is that every project deserves proper attention to
              detail and care - no matter the size.
            </p>
            <p className="mb-4 leading-tight text-[5vw] md:text-[2.5vh] text-slate2">
              It is this belief that has propelled me further into refining my
              craft and bringing the most I can to a team. With years of
              experience in the field, I have developed a strong understanding
              of the entire software development life cycle, from idea to
              deployment and I'm comfortable jumping in at any stage.
            </p>
            <p className="mb-4 leading-tight text-[5vw] md:text-[2.5vh] text-slate2">
              I love what I do and am dedicated to creating and maintaining
              high-quality applications that meet the needs of clients and users
              alike. I am excited to continue growing and evolving as a
              developer and look forward to new challenges and opportunities.
            </p>
            <p className="mb-4 leading-tight text-[5vw] md:text-[2.5vh] text-slate2">
              When I'm not deep in code, you can find me tending to the
              garden(embracing my inner Monty Don), out in nature with my wife,
              or sharing a sun spot with my cat.
            </p>
          </div>
        </div>
      </div>
      <div className={"w-[100vw] bg-orange2"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-3rem",
          }}
          className={"bg-orange2 w-[85%] md:w-[90%] mx-auto lg:max-w-[1400px]"}
        >
          <h4 className="text-slate2 leading-tight text-[16vw] md:text-[10vh] mt-2 mb-7 md:mx-auto">
            Experience
          </h4>
          <div className="ml-0 lg:w-2/3 sticky md:hidden">
            <div className="md:mx-auto w-full h-full">
              <div className="relative wrap md:overflow-hidden h-full">
                <div className="border-2-2 border-yellow2 absolute h-full border left-[1vw] md:left-[50%]"></div>
                <div className="mb-8 flex justify-start md:justify-between md:flex-row-reverse items-center w-full right-timeline md:left-timeline">
                  <div className="order-1 w-6 "></div>
                  <div className="order-1 w-[100%] md:w-fit max-w-[425px] px-1 py-4 text-left md:text-right relative flex flex-row-reverse">
                    <div>
                      <span className="mb-2 text-[5vw] md:text-[2vh] flex items-center md:text-right md:relative md:flex-row-reverse">
                        <div className="h-[3vw] w-[3vw] rounded-full bg-yellow2 dark:bg-neutral-500 -ml-7 border-slate2 border-2"></div>
                        <span
                          className={
                            "md:text-right text-slate2 ml-5 md:mr-5 w-[100%]"
                          }
                        >
                          - Present
                        </span>
                      </span>
                      <span
                        className={
                          "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                        }
                      >
                        Freelance / Contract{" "}
                      </span>
                      <h4 className="mb-2 font-bold text-[7vw] md:text-[4vh] ml-1 sm:ml-5 text-slate2">
                        Full Stack Engineer{" "}
                      </h4>
                      <p className="text-[4vw] md:text-[1.5vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100">
                        Open for Freelance or Contract opportunities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-8 flex justify-start md:justify-between items-center w-full right-timeline">
                  <div className="order-1 w-6 md:w-5/12"></div>
                  <div className="order-1 w-[100%] md:w-5/12 px-1 py-4 text-left relative">
                    <span className="mb-2 text-[5vw] md:text-[2vh] text-yellow-300 flex items-center">
                      <div className="h-[3vw] w-[3vw] rounded-full bg-yellow2 dark:bg-neutral-500 -ml-7 border-slate2 border-2"></div>
                      <span className={"ml-5 text-slate2"}>
                        02/2021 - 12/2022
                      </span>
                    </span>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      Blue in Green
                    </span>
                    <h4 className="mb-2 font-bold text-[7vw] md:text-[4vh] ml-1 sm:ml-5 text-slate2">
                      Full Stack Engineer
                    </h4>
                    <ul className="text-[4vw] md:text-[1vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4">
                      <li>
                        Participate in Agile development process and deploy code
                        on regular basis
                      </li>
                      <li className={"mb-2"}>
                        Work with team to develop, deploy and maintain partner
                        web apps
                      </li>
                      <li className={"mb-2"}>
                        Meet regularly with stakeholders to present progress
                      </li>
                      <li className={"mb-2"}>
                        Provision all resources needed for development and
                        deployment
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mb-8 flex justify-start items-center w-full right-timeline">
                  <div className="order-1 w-6 md:w-5/12"></div>
                  <div className="order-1 w-[100%] px-1 py-4 text-left relative">
                    <span className="mb-2 text-[5vw] md:text-[2vh] text-yellow-300 flex items-center">
                      <div className="h-[3vw] w-[3vw] rounded-full bg-yellow2 dark:bg-neutral-500 -ml-7 border-slate2 border-2"></div>
                      <span className={"ml-5 text-slate2"}>
                        12/2019 - 01/2021
                      </span>
                    </span>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      Graybox
                    </span>
                    <h4 className="mb-2 font-bold text-[7vw] md:text-[4vh] ml-1 sm:ml-5 text-slate2">
                      Developer
                    </h4>
                    <ul className="text-[4vw] md:text-[1vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4">
                      <li className={"mb-2"}>
                        Work with team to develop, deploy and maintain partner
                        web apps
                      </li>
                      <li className={"mb-2"}>
                        Integrate APIs into ecommerce platforms using API
                        Gateway and Lambda
                      </li>
                      <li className={"mb-2"}>
                        Utilize ecommerce platform REST and GraphQL APIs to
                        enhance store functionality
                      </li>
                      <li className={"mb-2"}>
                        Provision AWS resources needed for development and
                        deployment
                      </li>
                      <li>
                        Participate in Agile development process and deploy code
                        on regular basis
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mb-8 flex justify-start items-center w-full right-timeline">
                  <div className="order-1 w-6 md:w-5/12"></div>
                  <div className="order-1 w-[100%] px-1 py-4 text-left relative">
                    <span className="mb-2 text-[5vw] md:text-[2vh] text-yellow-300 flex items-center">
                      <div className="h-[3vw] w-[3vw] rounded-full bg-yellow2 dark:bg-neutral-500 -ml-7 border-slate2 border-2"></div>
                      <span className={"ml-5 text-slate2"}>
                        12/2018 - 10/2019
                      </span>
                    </span>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      Visionem
                    </span>
                    <h4 className="mb-2 font-bold text-[7vw] md:text-[4vh] ml-1 sm:ml-5 text-slate2">
                      Software Developer
                    </h4>
                    <ul className="text-[4vw] md:text-[1vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4">
                      <li className={"mb-2"}>
                        Refactor and maintain organization’s multiple websites
                      </li>
                      <li className={"mb-2"}>
                        Develop, deploy and maintain both internal and external
                        facing apps
                      </li>
                      <li className={"mb-2"}>
                        Provision AWS resources needed for development and
                        deployment
                      </li>
                      <li className={"mb-2"}>
                        Participate in Agile development process and deploy code
                        on regular basis
                      </li>
                      <li>Upkeep organization’s IT infrastructure</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-8 flex justify-start items-center w-full right-timeline">
                  <div className="order-1 w-6 md:w-5/12"></div>
                  <div className="order-1 w-[100%] px-1 py-4 text-left relative">
                    <span className="mb-2 text-[5vw] md:text-[2vh] text-yellow-300 flex items-center">
                      <div className="h-[3vw] w-[3vw] rounded-full bg-yellow2 dark:bg-neutral-500 -ml-7 border-slate2 border-2"></div>
                      <span className={"ml-5 text-slate2"}>
                        02/2016 - 12/2018
                      </span>
                    </span>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      ThirdSun Development
                    </span>
                    <h4 className="mb-2 font-bold text-[7vw] md:text-[4vh] ml-1 sm:ml-5 text-slate2">
                      Founder
                    </h4>
                    <ul className="text-[4vw] md:text-[1vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4">
                      <li className={"mb-2"}>
                        Design and develop client’s website
                      </li>
                      <li className={"mb-2"}>
                        Migrate client’s website to cloud using Route 53, s3,
                        and CloudFront
                      </li>
                      <li className={"mb-2"}>
                        Provision and maintain both Linux and Windows EC2
                        instances inside client VPCs
                      </li>
                      <li className={"mb-2"}>
                        Provision both Linux and Windows instances as virtual
                        workstations
                      </li>
                      <li>Secure client resources using IAM</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-8 flex justify-start items-center w-full right-timeline">
                  <div className="order-1 w-6 md:w-5/12"></div>
                  <div className="order-1 w-[100%] px-1 py-4 text-left relative">
                    <span className="mb-2 text-[5vw] md:text-[2vh] text-yellow-300 flex items-center">
                      <div className="h-[3vw] w-[3vw] rounded-full bg-yellow2 dark:bg-neutral-500 -ml-7 border-slate2 border-2"></div>
                      <span className={"ml-5 text-slate2"}>
                        07/2015 - 02/2016
                      </span>
                    </span>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      The Soap Studio
                    </span>
                    <h4 className="mb-2 font-bold text-[7vw] md:text-[4vh] ml-1 sm:ml-5 text-slate2">
                      Content Manager
                    </h4>
                    <ul className="text-[4vw] md:text-[1vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4">
                      <li className={"mb-2"}>
                        Created and maintained weekly content release schedule
                      </li>
                      <li className={"mb-2"}>
                        Curated client content and tracked engagement of
                        customers and followers
                      </li>
                      <li>
                        Researched and acquired affiliate accounts that matched
                        client interests
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-0 lg:w-[80%] md:mx-auto hidden md:flex">
            <div className="mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden p-10 h-full">
                <div
                  className="border-2-2 border-yellow-555 absolute h-full border"
                  style={{
                    right: "50%",
                    border: "2px solid #FFC100",
                    borderRadius: "1%",
                  }}
                ></div>
                <div
                  className="border-2-2 border-yellow-555 absolute h-full border"
                  style={{
                    right: "50%",
                    border: "2px solid #FFC100",
                    borderRadius: "1%",
                  }}
                ></div>
                <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                  <div className="order-1 w-5/12"></div>
                  <div className="order-1 w-5/12 px-1 py-4 text-right">
                    <p className="mb-3 text-[5vw] md:text-[2vh] text-slate2">
                      - Present
                    </p>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      Freelance / Contract{" "}
                    </span>
                    <h4 className="mb-3 font-bold text-[7vw] md:text-[4vh] md:leading-tight text-slate2">
                      Full Stack Engineer
                    </h4>
                    <p className="text-[4vw] md:text-[2vh] leading-snug text-slate2 text-opacity-100">
                      Open for Freelance or Contract opportunities.
                    </p>
                  </div>
                </div>
                <div className="mb-8 flex justify-between items-center w-full right-timeline">
                  <div className="order-1 w-5/12"></div>
                  <div className="order-1  w-5/12 px-1 py-4 text-left">
                    <p className="mb-3 text-[5vw] md:text-[2vh] text-slate2">
                      02/2021 - 12/2022
                    </p>
                    <span className={"text-slate2 text-[6vw] md:text-[3vh] "}>
                      Blue in Green
                    </span>
                    <h4 className="mb-3 font-bold text-[7vw] md:text-[4vh] text-slate2 md:leading-tight">
                      Full Stack Engineer
                    </h4>
                    <ul className="text-[4vw] md:text-[2vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4">
                      <li>
                        Participate in Agile development process and deploy code
                        on regular basis
                      </li>
                      <li className={"mb-2"}>
                        Work with team to develop, deploy and maintain partner
                        web apps
                      </li>
                      <li className={"mb-2"}>
                        Meet regularly with stakeholders to present progress
                      </li>
                      <li className={"mb-2"}>
                        Provision all resources needed for development and
                        deployment
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                  <div className="order-1 w-5/12"></div>
                  <div className="order-1 w-5/12 px-1 py-4 text-right">
                    <p className="mb-3 text-[5vw] md:text-[2vh] text-slate2">
                      12/2019 - 01/2021
                    </p>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      Graybox
                    </span>
                    <h4 className="mb-3 font-bold text-[7vw] md:text-[4vh] md:leading-tight text-slate2">
                      Developer
                    </h4>
                    <ul className="text-[4vw] md:text-[2vh] leading-snug text-slate2 text-opacity-100 list-disc bullets-left pr-4">
                      <li className={"mb-2"}>
                        Work with team to develop, deploy and maintain partner
                        web apps
                      </li>
                      <li className={"mb-2"}>
                        Integrate APIs into ecommerce platforms using API
                        Gateway and Lambda
                      </li>
                      <li className={"mb-2"}>
                        Utilize ecommerce platform REST and GraphQL APIs to
                        enhance store functionality
                      </li>
                      <li className={"mb-2"}>
                        Provision AWS resources needed for development and
                        deployment
                      </li>
                      <li>
                        Participate in Agile development process and deploy code
                        on regular basis
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mb-8 flex justify-between items-center w-full right-timeline">
                  <div className="order-1 w-5/12"></div>

                  <div className="order-1  w-5/12 px-1 py-4">
                    <p className="mb-3 text-[5vw] md:text-[2vh] text-slate2">
                      12/2018 - 10/2019
                    </p>
                    <span className={"text-slate2 text-[6vw] md:text-[3vh] "}>
                      Visionem
                    </span>
                    <h4 className="mb-3 font-bold text-[7vw] md:text-[4vh] md:leading-tight text-left text-slate2">
                      Software Developer
                    </h4>
                    <ul className="text-[4vw] md:text-[2vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4">
                      <li className={"mb-2"}>
                        Refactor and maintain organization’s multiple websites
                      </li>
                      <li className={"mb-2"}>
                        Develop, deploy and maintain both internal and external
                        facing apps
                      </li>
                      <li className={"mb-2"}>
                        Provision AWS resources needed for development and
                        deployment
                      </li>
                      <li className={"mb-2"}>
                        Participate in Agile development process and deploy code
                        on regular basis
                      </li>
                      <li>Upkeep organization’s IT infrastructure</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                  <div className="order-1 w-5/12"></div>
                  <div className="order-1 w-5/12 px-1 py-4 text-right">
                    <p className="mb-3 text-[5vw] md:text-[2vh] text-slate2">
                      02/2016 - 12/2018
                    </p>
                    <span
                      className={
                        "ml-1 sm:ml-5 text-slate2 text-[6vw] md:text-[3vh] "
                      }
                    >
                      ThirdSun Development
                    </span>
                    <h4 className="mb-3 font-bold text-[7vw] md:text-[4vh] md:leading-tight text-slate2">
                      Founder
                    </h4>
                    <ul className="text-[4vw] md:text-[2vh] leading-snug text-slate2 text-opacity-100 list-disc bullets-left pr-4">
                      <li className={"mb-2"}>
                        Design and develop client’s website
                      </li>
                      <li className={"mb-2"}>
                        Migrate client’s website to cloud using Route 53, s3,
                        and CloudFront
                      </li>
                      <li className={"mb-2"}>
                        Provision and maintain both Linux and Windows EC2
                        instances inside client VPCs
                      </li>
                      <li className={"mb-2"}>
                        Provision both Linux and Windows instances as virtual
                        workstations
                      </li>
                      <li>Secure client resources using IAM</li>
                    </ul>
                  </div>
                </div>
                <div className="mb-8 flex justify-between items-center w-full right-timeline">
                  <div className="order-1 w-5/12"></div>

                  <div className="order-1  w-5/12 px-1 py-4">
                    <p className="mb-3 text-[5vw] md:text-[2vh] text-slate2">
                      07/2015 - 02/2016
                    </p>
                    <span className={"text-slate2 text-[6vw] md:text-[3vh] "}>
                      The Soap Studio
                    </span>
                    <h4 className="mb-3 font-bold text-[7vw] md:text-[4vh] md:leading-tight text-left text-slate2">
                      Content Manager
                    </h4>
                    <ul className="text-[4vw] md:text-[2vh] ml-1 sm:ml-5 leading-snug text-slate2 text-opacity-100 list-disc pl-4 ">
                      <li className={"mb-2"}>
                        Created and maintained weekly content release schedule
                      </li>
                      <li className={"mb-2"}>
                        Curated client content and tracked engagement of
                        customers and followers
                      </li>
                      <li className={"mb-2"}>
                        Researched and acquired affiliate accounts that matched
                        client interests
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
