import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "../components/Header";
import { useTracking } from "../hooks/useTracking";
import Footer from "../components/Footer";
import React from "react";

export default function Root() {
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  return (
    <>
      <ResponsiveAppBar />
      <div id="detail">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}
