import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_CHATS_BY_USER } from "../utils/getChats";
import DeleteChat from "./DeleteChat";
import EditChat from "./EditChat";
import NewChatButton from "./NewChatButton";
import { ChatActionTypes } from "../reducers/chatReducer";
import { ChatContext } from "../context/chatContext";

function ChatSidebar() {
  const { id, username, updateChatHandler } = useContext(ChatContext);
  const { loading, error, data, refetch } = useQuery(GET_CHATS_BY_USER, {
    variables: {
      username: username,
    },
  });
  const [chats, setChats] = useState([]);

  useEffect(() => {
    (async () => {
      if (data !== undefined) {
        await refetch({
          username: username,
        }).then(async (data) => {
          const response = await data.data["getChatsByUser"];
          setChats(response);
        });
      }
    })();
  }, [data]);

  useEffect(() => {
    updateChat(id);
  }, [id]);

  async function updateChat(id: string) {
    await refetch({
      username: username,
    }).then(async (data) => {
      const response = await data.data["getChatsByUser"];

      if (response.length !== 0) {
        const newResponse = await response.filter((chat: any) => {
          return chat.id === id;
        });
        const notParsedMessages = newResponse[0].messages;
        const newMessages = notParsedMessages.map((message: any) => {
          return JSON.parse(message);
        });

        setChats(response);

        updateChatHandler({
          type: ChatActionTypes.UPDATE_ACTIVE_CHAT,
          payload: {
            id: newResponse[0].id,
            title: newResponse[0].title,
            organization: newResponse[0].organization,
            username: newResponse[0].username,
            messages: newMessages,
          },
        });
      }
    });
  }

  if (loading)
    return (
      <div className="h-[100%] flex items-center justify-center">
        <div
          className="text-center mx-auto border-2 border-mint2z-[1000] h-8 w-8 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-mint2 align-[-0.125em] text-mint2 opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="flex items-center justify-center ">
        <p className="text-mint2 text-4xl md:text-[4vh] !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Error : {error.message}
        </p>
      </div>
    );

  return (
    <div className="text-xl overflow-ellipsis w-[100%]">
      <h3 className="ml-[5%] text-3xl w-[100%]">Chat Archive</h3>
      <NewChatButton />
      <hr className="w-[95%] mx-auto mt-6" />
      <ul className="p-4 overflow-hidden w-[90%]">
        {chats.map((listChat: any, index: number) => {
          return (
            <li
              key={listChat.id}
              className={`flex flex-col text-left py-4 ${
                listChat.id === id ? "underline decoration-2" : ""
              }`}
            >
              <h3
                className="text-2xl cursor-pointer"
                onClick={() => {
                  updateChat(listChat.id);
                }}
              >
                {listChat.title}
              </h3>
              <div className="flex pt-2">
                <DeleteChat id={listChat.id} />
                <EditChat data={listChat} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ChatSidebar;
