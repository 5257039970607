import ProductList from "../components/ProductList";
import AddProduct from "../components/AddProduct";
import Login from "../components/Login";
import React from "react";

function Store() {
  const isAdmin = sessionStorage.getItem("isAdmin");

  if (isAdmin === "false") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3>You are not Authorized to see this Page. Return Home?</h3>
      </div>
    );
  } else {
    return (
      <>
        <Login />
        This is store me
        {
          // @ts-ignore
          <AddProduct />
        }
        <ProductList />
      </>
    );
  }
}

export default Store;
