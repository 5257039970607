import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useReducer,
} from "react";
import {
  ChatActionTypes,
  ChatAction,
  chatReducer,
  ChatState,
} from "../reducers/chatReducer";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery } from "@apollo/client";
import { GET_CHATS_BY_USER } from "../utils/getChats";

type ChatContextObject = ChatState & {
  updateChatHandler: (action: ChatAction) => void;
};

const defaultChatState: ChatState = {
  id: "",
  title: "",
  organization: "matthewrobison.com",
  username: "false",
  messages: [],
};

const defaultChatContext: ChatContextObject = {
  ...defaultChatState,
  // eslint-disable-next-line no-unused-vars
  updateChatHandler: (action: ChatAction) => {},
};

export const ChatContext = createContext<ChatContextObject>(defaultChatContext);

export const ChatContextProvider: FC = (props: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(chatReducer, defaultChatState);
  const contextValue: ChatContextObject = {
    ...state,
    updateChatHandler: dispatch,
  };
  const { user } = useAuthenticator((context) => [context.user]);
  const { loading, error, data } = useQuery(GET_CHATS_BY_USER, {
    variables: {
      username: user?.username,
    },
  });

  useEffect(() => {
    if (!loading && user !== undefined) {
      const newUser = data.getChatsByUser;

      if (newUser.length > 0) {
        const needParse = data?.getChatsByUser[0].messages;
        const parsedMessagaes = needParse.map((message: any) => {
          return JSON.parse(message);
        });

        dispatch({
          type: ChatActionTypes.UPDATE_ACTIVE_CHAT,
          payload: {
            new: true,
            id: newUser[0].id,
            title: newUser[0].title,
            organization: newUser[0].organization,
            username: user.username,
            messages: parsedMessagaes,
          },
        });
      } else {
        dispatch({
          type: ChatActionTypes.UPDATE_ACTIVE_CHAT,
          payload: {
            id: "",
            title: "",
            organization: "matthewrobison.com",
            username: user.username,
            messages: [],
          },
        });
      }
    }
  }, [loading]);

  return (
    <ChatContext.Provider value={contextValue}>
      {props.children}
    </ChatContext.Provider>
  );
};
