import { gql } from "@apollo/client";

export const CREATE_NEW_CONTACT = gql`
  mutation CreateContact($name: String, $email: String, $message: String) {
    createContact(name: $name, email: $email, message: $message) {
      name
      email
    }
  }
`;
