import * as React from "react";

import { FunctionComponent, useState, useCallback } from "react";

type TextTruncateProps = {
  text: string;
};

/* Note: The number of lines to truncate to needs to be static
otherwise the css classes won't be generated by tailwind. If you need
a different number of lines, create a new component */
export const TextTruncateThree: FunctionComponent<TextTruncateProps> = ({
  text,
}) => {
  const [shouldTruncate, setShouldTruncate] = useState<boolean>(false);
  const [readMore, setReadMore] = useState<boolean>(false);

  // Measure the element to calculate the number of lines and
  // determine whether to truncate
  const measuredRef = useCallback(
    (node: any) => {
      // Before the component mounts the node ref will be null
      if (node?.parentElement) {
        // Calculate the number of lines based on height
        const elLineCount = text.length;

        setShouldTruncate(elLineCount > 210);
      }
    },
    [text]
  );

  const shouldClamp = shouldTruncate && !readMore;

  // Our toggle for expanding or hiding truncated text
  let toggle;
  if (readMore) {
    toggle = (
      <span
        className={
          "cursor-pointer hover:underline text-[#3a71ca] text-[5vw] md:text-[4vh]"
        }
        onClick={() => setReadMore(false)}
      >
        Show less
      </span>
    );
  } else {
    toggle = (
      <span
        className={
          "cursor-pointer hover:underline text-[#3a71ca] text-[5vw] md:text-[4vh]"
        }
        onClick={() => setReadMore(true)}
      >
        Read more
      </span>
    );
  }

  return (
    <div>
      <p
        ref={measuredRef}
        className={`${shouldClamp ? "line-clamp-3" : "line-clamp-none"}`}
      >
        {text}
      </p>
      {shouldTruncate && toggle}
    </div>
  );
};
