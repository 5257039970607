import { Reducer } from "react";

export type ChatState = {
  id: string;
  title: string;
  organization: string;
  username: string | undefined;
  messages: any[];
};

export enum ChatActionTypes {
  LOAD_CHAT = "LOAD_CHAT",
  CREATE_NEW_CHAT = "CREATE_NEW_CHAT",
  UPDATE_ACTIVE_CHAT = "UPDATE_ACTIVE_CHAT",
  UPDATE_MESSAGE_ARRAY = "UPDATE_MESSAGE_ARRAY",
  DELETE_CHAT = "DELETE_CHAT",
  SET_FETCHING = "SET_FETCHING",
}

export type ChatAction = {
  type: ChatActionTypes;
  payload: any;
};

export const defaultChatState: ChatState = {
  id: "",
  title: "",
  organization: "matthewrobison.com",
  username: "",
  messages: [],
};

export const chatReducer: Reducer<ChatState, ChatAction> = (
  prevState: ChatState,
  action: ChatAction
) => {
  const { type, payload } = action;

  switch (type) {
    case "LOAD_CHAT": {
      return;
    }
    case "CREATE_NEW_CHAT": {
      return {
        ...payload,
      };
    }
    case "UPDATE_ACTIVE_CHAT": {
      return {
        id: payload.id,
        title: payload.title,
        organization: payload.organization,
        username: payload.username,
        messages: payload.messages,
      };
    }
    case "UPDATE_MESSAGE_ARRAY": {
      const { username, id, title, organization, messages } = payload;

      return {
        username,
        id,
        title,
        organization,
        messages,
      };
    }
    case "DELETE_CHAT": {
      return;
    }
    case "SET_FETCHING": {
      return {
        ...prevState,
        fetching: payload.fetching,
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};
