import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";

// @ts-ignore
function Message({ message }) {
  const { user } = useAuthenticator((context) => [context.user]);
  const username = user.username;
  const newMessage = message.message;

  if (message.username === username) {
    return <div className="speech speech-human">{newMessage}</div>;
  } else {
    return <div className="speech speech-ai">{newMessage}</div>;
  }
}
export default Message;
