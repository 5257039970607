import { useQuery } from "@apollo/client";
import { GET_ALL_PROJECTS } from "../utils/getProjects";

import { useMemo } from "react";
import RemoveProject from "./RemoveProject";
import EditProject from "./EditProject";
import { TextTruncateThree } from "./TruncateThree";
import React from "react";

// @ts-ignore
function ProjectList(): React.JSX.Element | React.JSX.Element[] {
  const { loading, error, data } = useQuery(GET_ALL_PROJECTS);
  const isAdmin = sessionStorage.getItem("isAdmin");

  const groupBy = (key: any) => (array: any) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const cachedMutatedData = useMemo(() => {
    if (loading || error) return null;
    const groupByClient = groupBy("client");
    const newClients = groupByClient(data.allProjects);

    // mutate data here
    return newClients;
  }, [loading, error, data]);

  if (loading && cachedMutatedData === null)
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <div
          className="text-mint2 inline-block h-2 w-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="text-mint2 text-4xl md:text-[4vh] !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="flex items-center justify-center ">
        <p className="text-mint2 text-4xl md:text-[4vh] !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Error : {error.message}
        </p>
      </div>
    );

  if (cachedMutatedData !== null) {
    const clients = cachedMutatedData;
    const newClients = [];
    for (const client in clients) {
      newClients.push(
        <div
          id={clients[client][0].client.replace(/[^a-zA-Z0-9]/g, "")}
          key={clients[client][0].client.replace(/[^a-zA-Z0-9]/g, "")}
          className={"mt-6 w-[100%]"}
        >
          <div className="px-5 bg-slate2">
            <h2
              className="mb-0 text-[10vw] lg:text-[8vh] mt-5 text-mint2"
              id={`heading-${clients[client][0].client.replace(
                /[^a-zA-Z0-9]/g,
                ""
              )}`}
            >
              {clients[client][0].client}
            </h2>
            <div
              id={`collapse${clients[client][0].client.replace(
                /[^a-zA-Z0-9]/g,
                ""
              )}`}
              className="!visible "
              data-te-collapse-item
              aria-labelledby={`heading-${clients[client][0].client.replace(
                /[^a-zA-Z0-9]/g,
                ""
              )}`}
              data-te-parent={clients[client][0].client}
            >
              <div className="pb-4 px-5">
                {clients[client].map((proj: any) => (
                  <div
                    id={proj.name.replace(/[^a-zA-Z0-9]/g, "")}
                    key={proj.name.replace(/[^a-zA-Z0-9]/g, "")}
                  >
                    <div className="text-[6vw] dark:bg-neutral-800 py-2 lg:py-4">
                      <h2
                        className="mb-0 text-mint2]"
                        id={`heading-${proj.name.replace(/[^a-zA-Z0-9]/g, "")}`}
                      >
                        <button
                          className="group relative flex w-full h-[6vw] md:h-[6vh] items-center rounded-t-[15px] py-4 text-left text-base text-mint2 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(208,254,245)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                          type="button"
                          data-te-collapse-init
                          data-te-collapse-collapsed
                          data-te-target={`#collapse${proj.name.replace(
                            /[^a-zA-Z0-9]/g,
                            ""
                          )}`}
                          aria-expanded="false"
                          aria-controls={`collapse${proj.name.replace(
                            /[^a-zA-Z0-9]/g,
                            ""
                          )}`}
                        >
                          <span className={"text-[7vw] lg:text-[6vh] lg:pl-6"}>
                            {proj.name}
                          </span>
                          <span className="ml-auto -mr-1 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-[5vw] h-[5vw] md:w-[4vh] md:h-[4vh]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </span>
                        </button>
                      </h2>
                      <div
                        id={`collapse${proj.name.replace(/[^a-zA-Z0-9]/g, "")}`}
                        className="!visible hidden"
                        data-te-collapse-item
                        aria-labelledby={`heading-${proj.name.replace(
                          /[^a-zA-Z0-9]/g,
                          ""
                        )}`}
                        data-te-parent={proj.name.replace(/[^a-zA-Z0-9]/g, "")}
                      >
                        <div className="py-4 px-5 text-mint2 text-[5vw] md:text-[4vh] lg:pl-12">
                          <TextTruncateThree text={proj.description} />
                        </div>
                        <div className="md:py-3 flex flex-wrap justify-start lg:pl-10 ">
                          {proj.tech.map((tech: any) => (
                            <span
                              key={tech}
                              className="text-[4vw] my-2 md:text-[2vh] inline-block border-[1px] py-2 px-3 mx-4 border-mint2 whitespace-nowrap rounded bg-slate2 text-center align-baseline leading-none text-mint2"
                            >
                              {tech}
                            </span>
                          ))}
                        </div>
                        {isAdmin === "true" ? (
                          <div className={"flex my-4"}>
                            <RemoveProject id={proj.id} />
                            <EditProject data={proj} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <hr className={"w-[90%] m-auto text-mint2"} />
            </div>
          </div>
        </div>
      );
    }
    return newClients.reverse();
  }
}

export default ProjectList;
