import React from "react";
import { Link } from "react-router-dom";

function Logout() {
  return (
    <div style={{ display: "flex", justifyContent: "center", height: "60vh" }}>
      <h3 style={{ alignSelf: "center" }} className="text-[9vw] text-center">
        You've been logged out.
        <br />
        return{" "}
        <Link className={"underline"} to={"/"}>
          home
        </Link>
        ?
      </h3>
    </div>
  );
}

export default Logout;
