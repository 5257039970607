import ProjectList from "../components/ProjectList";
import { useState } from "react";
import React from "react";

function Home() {
  const [orientation, setOrientation] = useState("");

  window.onscroll = function () {
    // @ts-ignore
    const yellowBall = this.document.getElementById("yellowBall");
    const orangeBall = document.getElementById("orangeBall");
    // @ts-ignore
    const firstSlide = this.document.getElementById("firstSlide");
    // @ts-ignore
    const firstContent = this.document.getElementById("firstContent");

    const scrollTop = document.documentElement.scrollTop * 0.25;
    yellowBall.style.transform = `translateX(${scrollTop}vw)`;
    firstSlide.style.zIndex = "-2";
    firstContent.style.zIndex = "-2";
    // @ts-ignore
    orangeBall.style.transform = `translateX(-${
      scrollTop * 0.75
    }vw) rotateZ(${scrollTop}deg)`;
  };

  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  return (
    <div style={{ overflowX: "hidden" }} className={"scroll-smooth"}>
      <div
        style={{
          width: "100vw",
          height: "95vh",
        }}
        id="firstSlide"
        className="bg-slate2 relative z-[0]"
      >
        <div className="text-01xs md:text-12xl leading-tight mx-auto text-mint2 relative flex justify-center align-middle w-[100%] h-[100%]">
          <h2
            id="firstContent"
            className="mb-4 pb-4 text-[20vw] md:text-[16vw] leading-tight text-center self-center"
          >
            WELCOME
            <span
              onClick={() =>
                // @ts-ignore
                document
                  .getElementById("secondSlide")
                  .scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-[20vw] h-[20vw] m-auto stroke-mint2 hover:cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </h2>
          <div
            id={"yellowBall"}
            className="bg-yellow2 absolute -left-[100vh] top-0 z-[-1]"
            style={{
              width: "100vh",
              height: "100vh",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
      <div
        id="secondSlide"
        style={{ width: "100vw", height: "95vh" }}
        className={"bg-green2"}
      >
        <div
          className={"text-slate2 relative flex align-middle w-[100%] h-[100%]"}
        >
          <div className="mx-auto w-[90%] z-10 md:w-[75%] xl:max-w-[60vw] xl:mx-0 xl:ml-[15vw] self-center">
            <h4 className="text-[16vw] md:text-[10vh] leading-tight">
              Full Stack
            </h4>
            <h4 className="border-b text-[16vw] md:text-[10vh] leading-tight">
              Development
            </h4>
            <p className="mt-6 leading-tight text-[5vw] md:text-[3vh]">
              End-to-End Project ownership - Taking pride in every detail along
              the way. Experienced in developing and managing all aspects of an
              application, with a passion for innovation and user-friendliness.
            </p>
          </div>
          <div
            id={"orangeBall"}
            className="bg-orange2 absolute -right-[75vh] md:-right-[175vh] top-0"
            style={{
              width: "50vh",
              height: "95vh",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
      <div
        id="bottom"
        style={{
          width: "100vw",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          color: "#fff",
        }}
        className={"bg-slate2 border-t-[1px] border-slate2 flex flex-col"}
      >
        <h4 className="leading-tight text-[16vw] md:text-[10vh] mt-6 text-center mx-auto text-mint2">
          Recent Work
        </h4>
        <div className={"w-[90%] xl:max-w-[70vw] mx-auto h-[100%] mb-16"}>
          {
            // @ts-ignore
            <ProjectList />
          }
        </div>
      </div>
    </div>
  );
}

export default Home;
