import ProjectList from "../components/ProjectList";
import AddProject from "../components/AddProject";
import React from "react";

function Project() {
  const isAdmin = sessionStorage.getItem("isAdmin");

  if (isAdmin === "false") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3>You are not Authorized to see this Page. Return Home?</h3>
      </div>
    );
  } else {
    return (
      <div className={"relative"}>
        <AddProject />
        <h3
          className={
            "text-slate2 mt-8 text-[4vh] mx-auto block min-h-[auto] w-72 rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
          }
        >
          Projects:
        </h3>

        {
          // @ts-ignore
          <ProjectList />
        }
      </div>
    );
  }
}
export default Project;
