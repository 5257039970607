import { useMutation } from "@apollo/client";
import { GET_CHATS_BY_USER, UPDATE_CHAT } from "../utils/getChats";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { ChatActionTypes } from "../reducers/chatReducer";
import { ChatContext } from "../context/chatContext";

function EditChat(data: any) {
  const [edit, setEdit] = useState(false);
  const [chat, setChat] = useState();
  const [editChat, { loading, error }] = useMutation(UPDATE_CHAT, {
    refetchQueries: [
      { query: GET_CHATS_BY_USER }, // DocumentNode object parsed with gql
      "GetChatsByUser", // Query name
    ],
  });
  const { updateChatHandler } = useContext(ChatContext);

  useEffect(() => {
    setChat(data.data);
  }, [data.data]);

  function updateChat(id: any) {
    // @ts-ignore
    editChat({ variables: { ...chat } })
      .then(async (response) => {
        const allChats = await response.data["updateChat"];
        const newMessages = allChats.messages;
        const parsedMessages = newMessages.map((mes: any) => {
          return JSON.parse(mes);
        });

        updateChatHandler({
          type: ChatActionTypes.UPDATE_ACTIVE_CHAT,
          payload: {
            id: allChats.id,
            title: allChats.title,
            organization: allChats.organization,
            username: allChats.username,
            messages: parsedMessages,
          },
        });
        setEdit(false);
      })
      .catch((error) => console.log(error));
  }

  function handleInputChange(event: any) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    setChat({
      // @ts-ignore
      ...chat,
      [name]: value,
    });
  }

  if (loading) return <>"Submitting..."</>;
  if (error) return <>`Submission error! ${error.message}`</>;
  if (edit) {
    return (
      <>
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "auto",
            zIndex: 1000,
            maxWidth: "700px",
          }}
          className={
            "bg-slate2 opacity-2 p-8 rounded shadow-2xl border-2 border-mint2"
          }
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // @ts-ignore
              updateChat(chat.id);
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
            className={"text-[3vh] w-[70%] mx-auto"}
          >
            <label className={"text-mint2 mb-4"}>
              New Title:
              <input
                type="text"
                name="title"
                onChange={(event) => handleInputChange(event)}
                // @ts-ignore
                defaultValue={chat.title}
                className={
                  "bg-[#253b4b] rounded text-mint2 text-2xl w-[100%] my-4 p-2 border-solid border-[1px] border-mint2"
                }
              />
            </label>
            <button
              type="submit"
              className="w-full max-w-[250px] mx-auto text-[2vh] rounded bg-transparent text-[2rem] text-mint2 border-2 border-mint2 font-bold px-6 py-2.5 font-medium uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Update
            </button>
          </form>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            className="w-6 h-6 stroke-mint2 mr-[-20px] mt-[-20px]"
            onClick={() => setEdit(false)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </>
    );
  } else {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          className="w-6 h-6 stroke-mint2 cursor-pointer"
          onClick={() => setEdit(true)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
          />
        </svg>
      </>
    );
  }
}
export default EditChat;
