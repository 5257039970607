import { useEffect, useRef, useState } from "react";
import React from "react";

function Login() {
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("loggedIn"));

  useEffect(() => {
    if (loggedIn === "false") {
      getVideo();
    }
  }, [videoRef, loggedIn]);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        // @ts-ignore
        video.srcObject = stream;
        // @ts-ignore
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };
  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    // @ts-ignore
    let ctx = photo.getContext("2d");

    const width = 320;
    const height = 240;
    // @ts-ignore
    photo.width = width;
    // @ts-ignore
    photo.height = height;

    return setInterval(() => {
      ctx.drawImage(video, 0, 0, width, height);
    }, 20);
  };

  const takePhoto = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    // @ts-ignore
    const data = photo.toDataURL("image/jpeg").split(";base64,")[1];

    fetch(`${process.env.REACT_APP_FR_API}`, {
      method: "POST",
      body: JSON.stringify({
        image: data,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const messages = data.message;
        const checkIsAdmin = () => {
          const adminArray = messages.filter((message: any) =>
            message.persons.some((person: any) =>
              person.collections.some(
                (collection: any) => collection.name === "Admin"
              )
            )
          );
          if (adminArray.length > 0) {
            sessionStorage.setItem("isAdmin", "true");
            sessionStorage.setItem("loggedIn", "true");
            setLoggedIn("true");
          } else {
            sessionStorage.setItem("isAdmin", "false");
            sessionStorage.setItem("loggedIn", "false");
            setLoggedIn("false");
          }
        };

        checkIsAdmin();

        // @ts-ignore
        const mediaStream = video.srcObject;
        const tracks = mediaStream.getTracks();
        tracks.forEach((track: any) => track.stop());
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const logOut = () => {
    sessionStorage.setItem("loggedIn", "false");
    setLoggedIn("false");
  };

  return loggedIn === "true" ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          height: "240",
          maxWidth: "320px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "beige",
        }}
      >
        Logged In
      </div>
      <div className="flex justify-center space-x-2">
        <button
          type="button"
          data-te-ripple-init
          data-te-ripple-color="light"
          className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
          onClick={() => logOut()}
        >
          Log Out
        </button>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <video
        onCanPlay={() => paintToCanvas()}
        ref={videoRef}
        style={{ display: "none" }}
      />
      <canvas ref={photoRef} />
      <div className="flex justify-center space-x-2">
        <button
          type="button"
          data-te-ripple-init
          data-te-ripple-color="light"
          className="inline-block rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
          onClick={() => takePhoto()}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
