import { gql } from "@apollo/client";

export const CREATE_NEW_PROJECT = gql`
  mutation CreateProject(
    $name: String
    $description: String
    $client: String
    $tech: [String]
  ) {
    createProject(
      name: $name
      description: $description
      client: $client
      tech: $tech
    ) {
      name
      description
    }
  }
`;
