import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { DELETE_CHAT, GET_CHATS_BY_USER } from "../utils/getChats";
import { ChatActionTypes } from "../reducers/chatReducer";
import { ChatContext } from "../context/chatContext";

function DeleteChat(id: any): React.JSX.Element {
  const [deleteChat, { loading, error }] = useMutation(DELETE_CHAT, {
    refetchQueries: [
      { query: GET_CHATS_BY_USER }, // DocumentNode object parsed with gql
      "GetChatsByUser", // Query name
    ],
  });
  const { updateChatHandler } = useContext(ChatContext);

  function deleteChatById(id: any) {
    deleteChat({ variables: id })
      .then(async (response) => {
        if (response.data["deleteChat"].length === 0) {
          updateChatHandler({
            type: ChatActionTypes.UPDATE_ACTIVE_CHAT,
            payload: {
              id: "",
              title: "",
              organization: "",
              username: "",
              messages: [],
            },
          });
        } else {
          const allChats = await response.data["deleteChat"];
          const newMessages = allChats[0].messages;
          const parsedMessages = newMessages.map((mes: any) => {
            return JSON.parse(mes);
          });
          updateChatHandler({
            type: ChatActionTypes.UPDATE_ACTIVE_CHAT,
            payload: {
              id: allChats[0].id,
              title: allChats[0].title,
              organization: allChats[0].organization,
              username: allChats[0].username,
              messages: parsedMessages,
            },
          });
        }
      })
      .catch((error) => console.log(error));
  }

  if (loading) return <>"Submitting..."</>;
  if (error) return <>`Submission error! ${error.message}`</>;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 stroke-mint2 mr-2 cursor-pointer"
      onClick={() => deleteChatById(id)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
      />
    </svg>
  );
}
export default DeleteChat;
