import { gql } from "@apollo/client";

export const GET_ALL_CHATS = gql`
  query GetAllChats {
    getAllChats {
      id
      createdAt
      title
      username
    }
  }
`;

export const GET_CHATS_BY_USER = gql`
  query GetChatsByUser($username: String) {
    getChatsByUser(username: $username) {
      id
      createdAt
      title
      organization
      messages
    }
  }
`;

export const GET_CHAT_BY_ID = gql`
  query GetChatById($id: String) {
    getChatById(id: $id) {
      id
      createdAt
      title
      organization
      username
      messages
    }
  }
`;

export const DELETE_CHAT = gql`
  mutation DeleteChat($id: String) {
    deleteChat(id: $id) {
      id
      createdAt
      title
      organization
      username
      messages
    }
  }
`;

export const UPDATE_CHAT = gql`
  mutation UpdateChat(
    $id: String
    $title: String
    $organization: String
    $username: String
    $messages: [String]
  ) {
    updateChat(
      id: $id
      title: $title
      organization: $organization
      username: $username
      messages: $messages
    ) {
      id
      createdAt
      title
      organization
      username
      messages
    }
  }
`;
