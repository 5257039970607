import { useMutation } from "@apollo/client";
import { CREATE_NEW_CONTACT } from "../utils/createNewContact";
import { useEffect, useState } from "react";
import React from "react";

function ContactForm() {
  const [contact, setContact] = useState({});
  const [createContact, { error }] = useMutation(CREATE_NEW_CONTACT, {});
  const [success, setSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMedium, setIsMedium] = useState(false);

  if (window.innerWidth > 768 && isMedium === false) {
    setIsMedium(true);
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth > 768) {
      setIsMedium(true);
    } else {
      setIsMedium(false);
    }
  });

  useEffect(() => {
    const loadScriptByURL = (id: any, url: any, callback: any) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, []);

  const handleOnClick = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    window.grecaptcha.ready(() => {
      // @ts-ignore
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA, { action: "submit" })
        .then((token: any) => {
          submitData(token);
        });
    });
  };

  function submitData(token: any) {
    // @ts-ignore
    fetch(process.env.REACT_APP_RECAPTCHA_API, {
      method: "POST",
      body: JSON.stringify({
        token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const success = data.message.success;
        if (success) {
          addNewContact(contact);
          setLoading(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setSubmitError(true);
        }
      })
      .catch((error) => console.log(error));
  }
  function addNewContact(contact: any) {
    createContact({
      variables: {
        name: contact.name,
        email: contact.email,
        message: contact.message,
      },
    }).catch((error) => {
      console.log(error);
    });
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setContact({
      ...contact,
      [name]: value,
    });
  }

  if (loading)
    return (
      <div className="flex items-center justify-center">
        <div
          className="text-orange2 inline-block h-2 w-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="text-orange2 text-4xl md:text-[4vh] !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Sending...
          </span>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="flex items-center justify-center">
        <span className="text-orange2 text-4xl md:text-[4vh] !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          `Submission error! ${error.message}`{" "}
        </span>
      </div>
    );
  if (submitError)
    return (
      <div className="flex items-center justify-center">
        <span className="text-orange2 text-4xl md:text-[4vh] !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          There was an error processing your submission. Please try again.
        </span>
      </div>
    );
  if (success) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className=" border-8 border-[orange2] rounded-full p-10 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            className="w-36 h-36 md:w-48 md:h-48 stroke-orange2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
            />
          </svg>
        </div>
        <p className="text-4xl md:text-[4vh] text-orange2 text-center mt-6 md:mt-8 md:leading-snug">
          Thanks for reaching out!
          <br /> You'll hear back soon.
        </p>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
        className={"mx-auto mt-10"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-[20vw] h-[20vw] lg:w-[12vh] lg:h-[12vh] mx-auto fill-green2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>
        <div className="block rounded-lg bg-white mx-auto w-[100%] max-w-[800px] p-6dark:bg-neutral-700">
          <form
            id={"newContactForm"}
            className={"mt-6 w-100%"}
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              handleOnClick(e);
            }}
          >
            <div className="relative mb-6">
              <input
                name={"name"}
                required={true}
                type="text"
                id="contactName"
                className={
                  "text-slate2 text-[3vh] border-2 border-orange2 block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
                }
                placeholder="Name"
                onChange={(event) => handleInputChange(event)}
              />
            </div>
            <div className="relative mb-6">
              <input
                name={"email"}
                required={true}
                type="email"
                id="contactEmail"
                className={
                  "text-slate2 text-[3vh] border-2 border-orange2 block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
                }
                placeholder="Email address"
                onChange={(event) => handleInputChange(event)}
              />
            </div>
            <div className="relative mb-6">
              <textarea
                name={"message"}
                required={true}
                id="contactMessage"
                className={
                  "text-slate2 text-[3vh] border-2 border-orange2 block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
                }
                rows={isMedium ? 6 : 3}
                placeholder="Message"
                onChange={(event) => handleInputChange(event)}
              ></textarea>
            </div>
            <div className={"w-[100%] flex"}>
              <button
                type="submit"
                className="w-full text-[3vh] max-w-[50vw] max-w-[50%] md:max-w-[25%] mx-auto rounded bg-orange2 text-[2rem] text-slate2 hover:text-mint2 shadow-2xl !font-bold px-6 py-2.5 font-medium uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactForm;
