import { useMutation } from "@apollo/client";
import { CREATE_NEW_PROJECT } from "../utils/createNewProject";
import { GET_ALL_PROJECTS } from "../utils/getProjects";
import { useState } from "react";
import React from "react";

export interface project {
  name: string;
  description: string;
  client: string;
  tech: any[];
}

function AddProject() {
  const [project, setProject] = useState({
    name: "",
    description: "",
    client: "",
    tech: [],
  });
  const [createProject, { loading, error }] = useMutation(CREATE_NEW_PROJECT, {
    refetchQueries: [
      { query: GET_ALL_PROJECTS }, // DocumentNode object parsed with gql
      "GetAllProjects", // Query name
    ],
  });

  async function addNewProject() {
    // @ts-ignore
    await createProject({
      variables: {
        name: project.name,
        description: project.description,
        client: project.client,
        // @ts-ignore
        tech: project.tech.split(", "),
      },
    });
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setProject({
      ...project,
      [name]: value,
    });
  }

  if (loading) return <>"Submitting..."</>;
  if (error) return <>`Submission error! ${error.message}`</>;

  return (
    <div className="block rounded-lg bg-white mx-auto w-[100%] max-w-[800px] p-6dark:bg-neutral-700">
      <form
        className={"mt-6 w-100%"}
        onSubmit={(e) => {
          e.preventDefault();
          addNewProject();
        }}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="relative mb-6">
          <label
            className={
              "text-slate2 text-[2vh] block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
            }
          >
            Client:
            <input
              type="text"
              name="client"
              onChange={(event) => handleInputChange(event)}
              className={
                "text-slate2 text-[2vh] border-2 border-orange2 block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
              }
            />
          </label>
        </div>
        <div className="relative mb-6">
          <label
            className={
              "text-slate2 text-[2vh] block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
            }
          >
            Name:
            <input
              type="text"
              name="name"
              onChange={(event) => handleInputChange(event)}
              className={
                "text-slate2 text-[2vh] border-2 border-orange2 block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
              }
            />
          </label>
        </div>
        <div className="relative mb-6">
          <label
            className={
              "text-slate2 text-[2vh] block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
            }
          >
            Tech:
            <input
              type="text"
              name="tech"
              onChange={(event) => handleInputChange(event)}
              className={
                "text-slate2 text-[2vh] border-2 border-orange2 block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
              }
            />
          </label>
        </div>
        <div className="relative mb-6">
          <label
            className={
              "text-slate2 text-[2vh] block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
            }
          >
            Description:
            <textarea
              rows={6}
              name="description"
              onChange={(event) => handleInputChange(event)}
              className={
                "text-slate2 text-[2vh] border-2 border-orange2 block min-h-[auto] w-full rounded bg-transparent py-[0.32rem] px-3 leading-[1.6]"
              }
            />
          </label>
        </div>
        <button
          type="submit"
          className="w-[90%] mx-auto text-[2vh] rounded bg-orange2 text-[2rem] text-green2 font-bold px-6 py-2.5 font-medium uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
          data-te-ripple-init
          data-te-ripple-color="light"
        >
          Add Project
        </button>
      </form>
    </div>
  );
}
export default AddProject;
