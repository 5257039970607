import { gql } from "@apollo/client";

export const CREATE_NEW_PRODUCT = gql`
  mutation CreateProduct(
    $name: String
    $description: String
    $price: Float
    $rating: Float
  ) {
    createProduct(
      name: $name
      description: $description
      price: $price
      rating: $rating
    ) {
      name
      description
    }
  }
`;
