import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./Error-page";
import Contact from "./routes/Contact";
import Root from "./routes/Root";
import About from "./routes/About";
import Store from "./routes/Store";
import Home from "./routes/Home";
import Chat from "./routes/Chat";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import Project from "./routes/Project";
import * as te from "tw-elements";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { ChatContextProvider } from "./context/chatContext";

Amplify.configure(awsExports);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    index: <Home />,
    children: [
      {
        path: "/",
        element: <Home title="Home" />,
        index: true,
      },
      {
        path: "contact",
        element: <Contact title="Contact" />,
      },
      {
        path: "about",
        element: <About title="About" />,
      },
      {
        path: "shop",
        element: <Store title="Store" />,
      },
      {
        path: "login",
        element: <Login title="Login" />,
      },
      {
        path: "logout",
        element: <Logout title="Logout" />,
      },
      {
        path: "project",
        element: <Project title="Project" />,
      },
      {
        path: "chat",
        element: <Chat title="Chat" />,
      },
    ],
  },
]);

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_GRAPHQL_API}`,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Authenticator.Provider>
        <ChatContextProvider>
          <RouterProvider router={router} />
        </ChatContextProvider>
      </Authenticator.Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
