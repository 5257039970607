import { gql } from "@apollo/client";

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    allProjects {
      id
      name
      description
      client
      tech
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: String) {
    deleteProject(id: $id) {
      name
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: String
    $name: String
    $client: String
    $description: String
    $tech: [String]
  ) {
    updateProject(
      id: $id
      name: $name
      client: $client
      description: $description
      tech: $tech
    ) {
      name
      description
      client
      tech
    }
  }
`;
