import ContactForm from "../components/ContactForm";
import { useState } from "react";
import React from "react";

function Contact() {
  const [orientation, setOrientation] = useState("");

  window.onscroll = function () {
    // @ts-ignore
    const yellowBall = this.document.getElementById("yellowBall");
    // @ts-ignore
    const firstSlide = this.document.getElementById("firstSlide");
    // @ts-ignore
    const firstContent = this.document.getElementById("firstContent");
    const scrollTop = document.documentElement.scrollTop * 0.25;
    yellowBall.style.transform = `translateX(${scrollTop}vw)`;
    firstSlide.style.zIndex = "-2";
    firstContent.style.zIndex = "-2";
  };
  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  return (
    <div style={{ overflowX: "hidden" }} className={"scroll-smooth"}>
      <div
        style={{
          width: "100vw",
          height: "95vh",
        }}
        id="firstSlide"
        className="bg-slate2 relative z-[0]"
      >
        <div className="text-01xs md:text-12xl leading-tight mx-auto text-mint2 flex justify-center align-middle w-[100%] h-[100%]">
          <h2
            id="firstContent"
            className="mb-4 pb-4 text-[20vw] md:text-[16vw] leading-tight text-center self-center"
          >
            SAY HI
            <span
              onClick={() =>
                // @ts-ignore
                document
                  .getElementById("bottom2")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                className="w-[20vw] h-[20vw] m-auto stroke-mint2 hover:cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </h2>
          <div
            id={"yellowBall"}
            className="bg-yellow2 absolute -left-[100vh] top-0 z-[-1]"
            style={{
              width: "100vh",
              height: "100vh",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
      <div
        id="bottom2"
        style={{
          width: "100vw",
          height: orientation === "landscape" ? "100%" : "100vh",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingBottom: orientation === "landscape" ? "100vh" : "100%",
        }}
        className={"text-orange2 bg-green2"}
      >
        <ContactForm />
      </div>
    </div>
  );
}

export default Contact;
