import React from "react";

function Footer() {
  return (
    <footer className="text-center dark:bg-neutral-700 lg:text-left">
      <div className="p-4 text-center text-slate2 dark:text-neutral-200">
        © 2023 Copyright:
        <a className="text-slate2 dark:text-neutral-400" href="/contact">
          {" "}
          Matthew Robison
        </a>
      </div>
    </footer>
  );
}

export default Footer;
