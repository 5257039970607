import React, { useContext, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Message from "../components/Message";
import ChatSidebar from "../components/ChatSidebar";
import { Link } from "react-router-dom";
import { ChatActionTypes } from "../reducers/chatReducer";
import { ChatContext } from "../context/chatContext";

function Chat() {
  const [orientation, setOrientation] = useState("");
  const { user } = useAuthenticator((context) => [context.user]);
  const [prompt, setPrompt] = useState("");
  const [fetching, setFetching] = useState(false);
  const loggedIn = sessionStorage.getItem("loggedIn");
  const { id, title, messages, organization, updateChatHandler } =
    useContext(ChatContext);

  useEffect(() => {
    const chatbot = document.getElementById("chatbot-conversation");
    chatbot?.scrollBy({ top: chatbot.scrollHeight, behavior: "smooth" });
  }, [messages]);

  async function submitChat(newMessages: any[]) {
    setFetching(true);

    fetch(`${process.env.REACT_APP_OPENAI_API}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: user.username,
        prompt,
        id,
        title,
        organization,
        messages: newMessages,
      }),
    })
      .then((response) =>
        response.json().then((data) => {
          setFetching(false);
          const reply = JSON.parse(data.reply);
          let chatInfo;

          if (reply.data.hasOwnProperty("createChat")) {
            chatInfo = reply.data["createChat"];
          }

          if (reply.data.hasOwnProperty("updateChat")) {
            chatInfo = reply.data["updateChat"];
          }

          const newMessages = chatInfo.messages.map((message: any) => {
            return JSON.parse(message);
          });

          updateChatHandler({
            type: ChatActionTypes.UPDATE_MESSAGE_ARRAY,
            payload: {
              username: user.username,
              id: chatInfo.id,
              title: chatInfo.title,
              organization: chatInfo.organization,
              messages: newMessages,
            },
          });

          setPrompt("");
        })
      )
      .catch((error) => {
        if (error) {
          setFetching(false);
          const date = new Date();
          const botMessage = {
            createdAt: date,
            username: "SmileyBot",
            message:
              "Sorry, there seems to have been an issue, please submit your message again",
          };

          updateChatHandler({
            type: ChatActionTypes.UPDATE_MESSAGE_ARRAY,
            payload: {
              user: user.username,
              id,
              title,
              organization,
              messages: [...newMessages, botMessage],
            },
          });
        }
      });
  }

  async function addUserMessage(prompt: any) {
    const date = new Date();
    const userMessage = {
      createdAt: date,
      username: user.username,
      message: prompt,
    };

    const newMessages = [...messages, userMessage];

    updateChatHandler({
      type: ChatActionTypes.UPDATE_MESSAGE_ARRAY,
      payload: {
        user: user.username,
        id,
        title,
        organization,
        messages: newMessages,
      },
    });

    await submitChat(newMessages);
  }

  /*window.onscroll = function () {
    // @ts-ignore
    const yellowBall = this.document.getElementById("yellowBall");
    // @ts-ignore
    const firstSlide = this.document.getElementById("firstSlide");
    // @ts-ignore
    const firstContent = this.document.getElementById("firstContent");
    const scrollTop = document.documentElement.scrollTop * 0.25;
    yellowBall.style.transform = `translateX(${scrollTop}vw)`;
    firstSlide.style.zIndex = "-2";
    firstContent.style.zIndex = "-2";
  };*/
  let portrait = window.matchMedia("(orientation: portrait)");

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  });

  if (!loggedIn) {
    return (
      <>
        <div className="flex items-center justify-center flex-col text-center h-[80vh] w-[70%] my-auto mx-auto">
          <p className="text-slate2 text-2xl md:text-[4vh] leading-[4vh]">
            Looks like you are trying to resource that requires you to be
            signed-in.
          </p>

          <p className="text-slate2 text-2xl md:text-[4vh] leading-[4vh] mt-6">
            Please head back to{" "}
            <Link to="/login" className="underline">
              Login
            </Link>
          </p>
        </div>
      </>
    );
  }

  return (
    <div className={"scroll-smooth bg-slate2 "}>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        id="firstSlide"
        className="bg-slate2 relative z-[0] flex max-w-[1840px] mx-auto"
      >
        <div
          id="sideMenu"
          className="border-mint2 border-[1px] rounded-sm text-mint2 w-[25%] h-[85vh] mx-2 my-4 overflow-y-scroll p-4"
        >
          <ChatSidebar />
        </div>
        <div className="chatbot-container leading-tight m-auto text-mint2 flex w-[65%] h-[85vh] flex-col border-mint2 border-[1px] rounded-sm text-mint items-center p-4 relative">
          <div className="chatbot-header text-2xl text-center">
            <h1 className="supportId">
              Welcome{" "}
              {
                //@ts-ignore
                user?.username
              }
            </h1>
            <p>
              Start a New Chat{" "}
              {title !== "" ? `or continue ${title} below` : `.`}
            </p>
          </div>
          <div
            className="chatbot-conversation-container border-b-[1px] border-mint2 w-[100%]"
            id="chatbot-conversation"
          >
            <div className="speech speech-ai">Let's start a chat?</div>
            {messages
              ? messages.map((message: any, index: any) => {
                  return <Message key={index} message={message}></Message>;
                })
              : null}
            <div className="w-[100%] text-center absolute top-[50%] -translate-x-{50%} -translate-y-{50%} flex justify-center">
              {fetching ? (
                <div
                  className="text-center border-2 border-mint2z-[1000] h-14 w-14 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-mint2 align-[-0.125em] text-mint2 opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <form
            id="form"
            className="chatbot-input-container my-auto h-12 w-[80%] flex items-center justify-evenly"
          >
            <textarea
              name="user-input"
              rows={1}
              id="user-input"
              className="h-auto items-center w-[90%] bg-slate2 border-[1px] border-mint2 text-mint2 rounded-md p-4"
              placeholder="Send a message"
              value={prompt}
              onChange={(event) => setPrompt(event.target.value)}
              required
            />
            <button
              disabled={prompt === ""}
              id="submit-btn"
              className="submit-btn items-center cursor-pointer"
              onClick={(e) => {
                addUserMessage(prompt);
                e.preventDefault();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </button>
          </form>
        </div>

        {/*<div className="text-01xs md:text-12xl leading-tight mx-auto text-mint2 flex justify-center align-middle w-[100%] h-[100%]">
          <h2
            id="firstContent"
            className="mb-4 pb-4 text-[20vw] md:text-[16vw] leading-tight text-center self-center"
          >
            SAY HI
            <span
              onClick={() =>
                document
                  .getElementById("bottom2")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                className="w-[20vw] h-[20vw] m-auto stroke-mint2 hover:cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </h2>
        </div> */}
      </div>
    </div>
  );
}
export default Chat;
